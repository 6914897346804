// @flow

import React, { type Node } from "react";

import { Permissions } from "../../api/permissions";
import { UpdateCompany } from "../../containers";
import { usePermissions } from "../../hooks";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const CompanyUpdate = ({ id }: Props): Node => {
    const [canManage] = usePermissions([Permissions.PROJECT_MANAGE]);

    if (!id) {
        return <NotFoundPage />;
    }

    return <UpdateCompany companyId={id} readOnly={!canManage} />;
};

export default CompanyUpdate;
